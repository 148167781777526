import { render, staticRenderFns } from "./flexible-content.block.pug?vue&type=template&id=278ba0f5&scoped=true&lang=pug&"
import script from "./flexible-content.block.ts?vue&type=script&lang=ts&"
export * from "./flexible-content.block.ts?vue&type=script&lang=ts&"
import style0 from "./flexible-content.block.sass?vue&type=style&index=0&id=278ba0f5&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "278ba0f5",
  null
  
)

export default component.exports