import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import i18n from "@/i18n";

import { State, Getter, Action, Mutation } from "vuex-class";

import { EssayModel } from "@/data/essay/essay.model";

import { addFilter, getFilterObject, getActiveFilter, evalFilter } from "@/data/route";

@Component({
	name: "EssaysPage"
})
export default class EssaysPage extends Vue {
	@Getter("i18n/getCurrentLang") private currentLang!: string;
	@Getter("i18n/hasInitialized") private i18nHasInitialized!: boolean;

	@Mutation("state/setLoading") private setLoading!: any;

	private content: any = {};
	private currentPage: number = 1;
	private contentIsReady: boolean = false;
	private tags: any = {};
	private tagsIsReady: boolean = false;
	private url: string = window.location.href;
	private activeArticle: number = 0;

	private created() {
		this.getContent();
		if (this.$route.query.view) {
			this.$router.push({ query: { view: undefined } })
		}
		if (this.$route.query.page) {
			this.currentPage = Number(this.$route.query.page);
		} else {
			addFilter("page", "1");
		}
		this.getContent();
	}

	@Watch("currentLang")
	@Watch("i18nHasInitialized")
	private async getContent() {
		if (this.i18nHasInitialized) {
			this.setLoading(false);
			this.content = await new EssayModel().getList({ related_recursive_calls: 1 });
			this.contentIsReady = true;
		}
	}

	private setActiveArticle(id: number) {
		this.activeArticle = id;
	}

	// private ascNews(){
	// 	this.content.sort((a: any, b: any) => {
	// 		return a.name - b.name;
	// 	});
	// }

	// private desNews(){
	// 	this.content.sort((a: any, b: any) => {
	// 		return a.name + b.name;
	// 	});
	// }


	private callEvalFilter(filter: string, value: any, emptyReturn: boolean) {
		// The following line Makes no sense (at least for me) · Line is here for reactivity with template.
		const route = this.$route.query;
		return evalFilter(filter, value, emptyReturn);
	}

	private callAddFilter(filter: any, value: any, checkbox?: boolean, extra?: any) {
		addFilter(filter, value, checkbox, extra);
	}
}
