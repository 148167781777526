import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import i18n from "@/i18n";

import { ArticleModel } from "@/data/article/article.model";


import { State, Getter, Action, Mutation } from "vuex-class";

@Component({
	name: "ArticlePage"
})
export default class ArticlePage extends Vue {
	@Getter("i18n/getCurrentLang") private currentLang!: string;
	@Getter("i18n/hasInitialized") private i18nHasInitialized!: boolean;

	@Mutation("state/setLoading") private setLoading!: any;

	private content: any = {};
	private contentIsReady: boolean = false;
	private sidebarIsVisible: boolean = true;
	private url: string = window.location.href;

	private created() {
		this.getContent();
		if (this.$route.query.view) {
			this.$router.push({ query: { view: undefined } })
		}
		window.addEventListener("scroll", () => {
			this.checkSidebarVisibility();
		})
	}

	@Watch("currentLang")
	@Watch("i18nHasInitialized")
	private async getContent() {
		const slug = this.$route.params.slug;
		if (this.i18nHasInitialized) {
			this.setLoading(false);
			this.content = await new ArticleModel().getEntry({ related_recursive_calls: 2, slug });
			this.contentIsReady = true;
		}
	}

	@Watch("$route.query")
	private async refresh() {
		const slug = this.$route.params.slug;
		if (slug){
			this.contentIsReady = false;
			this.content = await new ArticleModel().getEntry({ related_recursive_calls: 2, slug });
			this.contentIsReady = true;
		}
	}

	private checkSidebarVisibility() {
		const sidebar = document.querySelector("#sidebar") as HTMLElement;

		const overflowElements = document.getElementsByClassName("sidebar-overflow") as HTMLCollection;
		// console.log("checkSidebarVisibility :: this.sidebarIsVisible -> ", this.sidebarIsVisible);

		if (sidebar) {
			const offset = window.scrollY + sidebar.getBoundingClientRect().top;
			for (const el of overflowElements) {
				const element = el as HTMLElement;
				offset + sidebar.offsetHeight > element.offsetTop && offset < element.offsetTop + element.offsetHeight ? this.sidebarIsVisible = false : this.sidebarIsVisible = true;
			}
		}
	}
}
