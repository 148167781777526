import { render, staticRenderFns } from "./persons.page.pug?vue&type=template&id=1c04ade6&scoped=true&lang=pug&"
import script from "./persons.page.ts?vue&type=script&lang=js&"
export * from "./persons.page.ts?vue&type=script&lang=js&"
import style0 from "./persons.page.sass?vue&type=style&index=0&id=1c04ade6&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c04ade6",
  null
  
)

export default component.exports