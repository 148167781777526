import { WpApi } from "@/data/wp-api";
import { Query } from "@/data/query";
import { Page } from "@/data/page/page";

import store from "@/store";

import { ImageAcf } from "@/data/image_acf";

import { ArticleModel } from "@/data/article/article.model";
import { DiscModel } from "@/data/disc/disc.model";
import { PersonModel } from "@/data/person/person.model";

export class PageModel {
	private namespace: string;
	private modelName: string;
	private api: WpApi;

	constructor() {
		this.namespace = "wp/v2";
		this.modelName = "pages";
		this.api = new WpApi();
	}

	/**
	 * Returns a single page.
	 */
	public async getEntry(queries?: Query): Promise<Page> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = await this.api.read(
				`${this.namespace}/${this.modelName}`,
				new Query({ ...queries, lang })
			);

			return this.processEntry(data[0]);
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Returns a template page.
	 */
	public async getTemplatePage(queries?: Query): Promise<Page> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = await this.api.read(`${this.namespace}/templates`, new Query({ ...queries, lang }));
			return this.processTemplateEntry(data[0]);
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Returns a list of pages.
	 */
	public async getList(queries?: Query): Promise<Page[]> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = await this.api.read(`${this.namespace}/${this.modelName}`, new Query({ ...queries, lang }));

			return data.map((entry: any) => {
				return this.processEntry(entry);
			});
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Processes a page entry.
	 */
	private processEntry(data: any): Page {
		// console.log("PageModel :: processEntry :: data -> ", data);

		// Process Portrait
		data.acf.image ? (data.acf.image = new ImageAcf(data.acf.image)) : (data.acf.image = null);

		// Process Flexible Content
		let processedFlexibleContent = data.acf.flexible_content;
		if (data.acf.flexible_content && data.acf.flexible_content.length) {
			processedFlexibleContent = data.acf.flexible_content.map((line: any) => {
				if (line.acf_fc_layout === "image" && line.images.length) {
					line.images = line.images.map((image: any) => {
						return new ImageAcf(image);
					})
				}
				if (data.related_ids && line.acf_fc_layout === "relation" && line.relations.length) {
					line.relations = line.relations.map((id: any) => {
						if (data.related_ids && data.related_ids[id].type === "person") {
							return new PersonModel().processEntry(data.related_ids[id]);
						} else if (data.related_ids && data.related_ids[id].type === "disc") {
							return new DiscModel().processEntry(data.related_ids[id]);
						}
					})
				}
				if (data.related_ids && line.acf_fc_layout === "document" && line.file) {
					line.file = data.related_ids[line.file];
				}
				return line;
			})
		}

		return {
			id: data.id,
			title: data.title.rendered,
			template: data.template,
			heading: data.acf.heading,
			headline: data.acf.headline,
			slug: data.slug,
			text: data.acf.text,
			image: data.acf.image,
			content: data.acf,
			flexibleContent: processedFlexibleContent
		};
	}

	/**
	 * Processes template page entry.
	 */
	private processTemplateEntry(data: any): any {
		// console.log("PageModel :: processEntry :: data -> ", data);

		let processedDiscs = data.acf.featured_discs;
		let processedNews = data.acf.featured_news;
		if (data.related_ids) {
			if (data.acf.featured_discs && data.acf.featured_discs.length) {
				processedDiscs = data.acf.featured_discs.map((id: any) => {
					if (data.related_ids[id]) {
						return new DiscModel().processEntry(data.related_ids[id]);
					}
				});
			}
			if (data.acf.featured_news && data.acf.featured_news.length) {
				processedNews = data.acf.featured_news.map((id: any) => {
					if (data.related_ids[id]) {
						return new ArticleModel().processEntry(data.related_ids[id]);
					}
				});
			}
		}

		return {
			title: data.acf.title,
			subtitle: data.acf.subtitle,
			description: data.acf.description,
			headline: data.acf.headline,
			article: data.acf.article,
			featuredDiscs: processedDiscs || [],
			featuredNews: processedNews || [],
			content: data.acf.content,
			pageTitle: data.acf.title,
			pageSubtitle: data.acf.subtitle,
			pageDescription: data.acf.description,
		};
	}
}
