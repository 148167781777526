import { WpApi } from "@/data/wp-api";
import { Query } from "@/data/query";
import { Disc } from "@/data/disc/disc";

import { ImageAcf } from "@/data/image_acf";

import { PersonModel } from "@/data/person/person.model";
import { TaxonomyModel } from "@/data/taxonomy/taxonomy.model";

import { Image } from "@/data/image";

import get from "lodash/get";

import store from "@/store";

export class DiscModel {
	private namespace: string;
	private modelName: string;
	private api: WpApi;

	constructor() {
		this.namespace = "wp/v2";
		this.modelName = "discs";
		this.api = new WpApi();
	}

	/**
	 * Returns a single page.
	 */
	public async getEntry(queries?: Query): Promise<Disc> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		
		try {
			const data = await this.api.read(
				`${this.namespace}/${this.modelName}`,
				new Query({ ...queries, lang })
			);

			return this.processEntry(data[0]);
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Returns a list of pages.
	 */
	public async getList(queries?: Query): Promise<any> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = await this.api.read(`${this.namespace}/${this.modelName}`, new Query({ ...queries, lang }));
			
			const processedData = data.map((entry: any) => {
				return this.processEntry(entry);
			});

			console.log("DiscModel :: getList :: data -> ", data)

			if (!data._paging) {
				return data;
			}

			return {
				content: processedData,
				total: Number(data._paging.total),
				totalPages: Number(data._paging.totalPages),
			}
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Processes a Entry.
	 */
	public processEntry(data: any): Disc {
		// console.log("DiscModel :: processEntry :: data -> ", data);

		let processedAuthors = data.acf.authors;
		let processedTracks = data.acf.tracks;
		let discCovers = data.acf.disc_covers;

		console.log("Disc covers ->", discCovers);

		if (data.related_ids) {
			if (data.acf.authors && data.acf.authors.length) {
				processedAuthors = data.acf.authors.map((author: any) => {
					if (data.related_ids[author]) {
						return new PersonModel().processEntry(data.related_ids[author]);
					}
				});
			}
			if (data.acf.tracks && data.acf.tracks.length) {
				processedTracks = data.acf.tracks.map((track: any) => {
					let authors;
					if(track.authors && track.authors.length) {
						authors = track.authors.map((author: any) => {
							// console.log("DiscModel :: processEntry :: author -> ", data.related_ids[author]);
							if (data.related_ids[author]) {
								return new PersonModel().processEntry(data.related_ids[author]);
							}
						});
					}
					return {
						name: track.name,
						reference: track.reference,
						authors
					}
				});
			}
			if (data.acf.disc_covers && data.acf.disc_covers.length) {
				discCovers = data.acf.disc_covers.map((cover: any) => {
					return new ImageAcf(cover);
				})
			}
		}

		// Process Taxonomies
		let processedYears = data.year;
		if(data.related_taxonomies) {
			if(data.year && data.year.length) {
				processedYears = data.year.map((year: number) => {
					return new TaxonomyModel("year").processEntry(data.related_taxonomies[year]);
				})
			}
		}

		// Process Cover
		data.acf.cover ? (data.acf.cover = new ImageAcf(data.acf.cover)) : (data.acf.cover = null);

		return {
			id: data.id,
			title: data.title.rendered,
			slug: data.slug,
			type: data.type,
			
			discCovers,
			format: data.acf.format,
			catNumber: data.acf.reference,
			studio: data.acf.studio,
			description: data.acf.description,
			
			tracks: processedTracks,
			authors: processedAuthors,
			years: processedYears
		};
	}
}
