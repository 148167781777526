import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import i18n from "@/i18n";

import { PageModel } from "@/data/page/page.model";

import { State, Getter, Action, Mutation } from "vuex-class";

@Component({
	name: "HeaderBlock"
})
export default class HeaderPage extends Vue {
	@Action("i18n/changeLanguage") private changeLanguage!: any;
	@Getter("i18n/getAvailableLangs") private availableLangs!: string[];
	@Getter("i18n/getCurrentLang") private currentLang!: string;
	@Getter("i18n/hasInitialized") private i18nHasInitialized!: boolean;

	private activePage: string = "";
	private hasScrolled: boolean = false;
	private menuIsOpen: boolean = false;
	private content: any = {};
	private contentIsReady: boolean = false;

	private created() {
		this.getContent();
	}

	@Watch("currentLang")
	@Watch("i18nHasInitialized")
	private async getContent() {
		if (this.i18nHasInitialized) {
			this.content = await new PageModel().getList();
			this.archiveLink();
			this.contentIsReady = true;
		}
	}

	get pageSlug(): any {
		return this.$route.name;
	}

	private archiveLink(){
		const discsPage = document.getElementById("discs");
		const personsPage = document.getElementById("persons");
		const archiveLink = document.getElementById("archive-link");

		if (discsPage.classList.contains("router-link-active") || personsPage.classList.contains("router-link-active")){
			archiveLink.classList.add("type--underline")
		}
	}

	private cleanMenuState() {
		this.toggleMenu(false);
		(document.activeElement as HTMLElement).blur();
	}

	private toggleMenu(force?: boolean): void {
		this.menuIsOpen = force !== undefined ? force : !this.menuIsOpen;
		document.body.classList.toggle("no--scroll", force);
	}

	private scrollTo(id: number) {
		const elTop = document.getElementById(id.toString()).offsetTop - 50;
		window.scrollTo({
			top: elTop,
			left: 0,
			behavior: "smooth"
		});
	}
}
