import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import i18n from "@/i18n";
import * as _ from "lodash";

import { PersonModel } from "@/data/person/person.model";
import { DiscModel } from "@/data/disc/disc.model";
import { ArticleModel } from "@/data/article/article.model";
import { DocumentModel } from "@/data/document/document.model";
import { EssayModel } from "@/data/essay/essay.model";
import { TaxonomyModel } from "@/data/taxonomy/taxonomy.model";

import { State, Getter, Action, Mutation } from "vuex-class";
import { addFilter, getFilterObject, getActiveFilter, evalFilter } from "@/data/route";

@Component({
	name: "SearchPage"
})
export default class SearchPage extends Vue {
	@Getter("i18n/getCurrentLang") private currentLang!: string;
	@Getter("i18n/hasInitialized") private i18nHasInitialized!: boolean;

	@Mutation("state/setLoading") private setLoading!: any;

	private hasQuery: boolean = false;
	private isWriting: boolean = false;
	private prevQuery: string = "";
	private searchQuery: string = "";
	
	private discs: any = {};
	private discsIsReady: boolean = false;
	private persons: any = {};
	private personsIsReady: boolean = false;
	private news: any = {};
	private newsIsReady: boolean = false;
	private documents: any = {};
	private documentsIsReady: boolean = false;
	private essays: any = {};
	private essaysIsReady: boolean = false;
	private url: string = window.location.href;

	private created() {
		if (this.$route.query.view){
			this.$router.push({ query: { view: undefined } });
		}
		if (this.$route.query.search){
			this.searchQuery = this.$route.query.search;
			this.getContent();
		}
		setInterval(() => {
			this.prevQuery = this.searchQuery;
		}, 2000);
	}

	@Watch("currentLang")
	@Watch("i18nHasInitialized")
	private async getContent() {
		this.setLoading(false)
		if (this.i18nHasInitialized ) {
			if (this.searchQuery.length > 0) {
				this.hasQuery = true;
				this.$router.push({ query: { search: this.searchQuery } })
				this.discsIsReady = false; 
				this.personsIsReady = false; 
				this.newsIsReady = false; 
				this.documentsIsReady = false; 
				this.essaysIsReady = false; 
				this.discs = await new DiscModel().getList({ search: this.searchQuery, related_recursive_calls: 1});
				this.discsIsReady = true; 
				this.persons = await new PersonModel().getList({ search: this.searchQuery });
				this.personsIsReady = true; 
				this.news = await new ArticleModel().getList({ search: this.searchQuery });
				this.newsIsReady = true; 
				this.documents = await new DocumentModel().getList({ search: this.searchQuery });
				this.documentsIsReady = true; 
				this.essays = await new EssayModel().getList({ search: this.searchQuery });
				this.essaysIsReady = true;
				
			} else {
				this.hasQuery = true;
			}
		}
	}

	private callEvalFilter(param: string, value: any) {
		if (this.$route.query && this.$route.query[param]) {
			return this.$route.query[param].includes(value);
		}
	}

	private callAddFilter(filter: any, value: any, checkbox?: boolean, extra?: any) {
		addFilter(filter, value, checkbox, extra);
	}
}
