// import VueCookies from "vue-cookies-ts";
// import { customAction } from "@/data/connection";
// import { bus } from "@/main.js";
import router from "@/router";

let filterObject: any = {};

/**
 * Converts Route to Object
 */
export const getFilterObject = (): any => {
	let object = {};

	for (const query in router.currentRoute.query) {
		if (query && router.currentRoute.query[query]) {
			object = {
				...object,
				[query]: router.currentRoute.query[query]
					.toString()
					.split(",")
			};
		}
	}
	filterObject = object;
	return filterObject;
};

/**
 * Ads Params to Route
 */
export const addFilter = (filter: any, value: any, checkbox?: boolean, extra?: any) => {
	// Prepares this.filter Object
	let object: any = {};
	if (extra) {
		object = extra;
	}

	filterObject = getFilterObject();

	// Removes id from Array
	if (filterObject[filter] && filterObject[filter].indexOf(value.toString()) >= 0) {
		if (filterObject[filter].length === 1) {
			filterObject[filter] = undefined;
		} else {
			filterObject[filter].splice(filterObject[filter].indexOf(value.toString()), 1);
		}
	}

	// Adds value to Array
	else {
		if (!filterObject[filter]) {
			filterObject[filter] = [];
		}
		filterObject[filter].push(value.toString());

		if (!checkbox) {
			filterObject[filter] = [value.toString()];
		}
	}

	// Converts Array to Route Object
	for (const key in filterObject) {
		if (filterObject.hasOwnProperty(key) && filterObject[key] !== undefined) {
			const v = filterObject[key];
			object = { ...object, [key]: v.join(",") };
		} else {
			object = { ...object, [key]: undefined };
		}
	}

	// Push Object to Route
	router.push({ query: object });
};

/**
 * Evaluates if Filter is on Router
 */
export const evalFilter = (filter: string, value: any, emptyReturn?: boolean) => {
	let routeValues: any = [];
	
	if (router.currentRoute.query && router.currentRoute.query[filter]) {
		routeValues = router.currentRoute.query[filter].toString().split(",");
	}

	if (routeValues.includes(value.toString())) {
		return true;
	} else if ( routeValues.length === 0 && emptyReturn) {
		return true;
	} else {
		return false;
	}
};

/**
 * Evaluates if Filter is on Router
 */
export const evalPage = (page: string, params?: any) => {
	if (router.currentRoute.name === page) {
		if (!params) {
			return true;
		}
		if (params && router.currentRoute.params === params) {
			return true;
		}
		if (params && router.currentRoute.params !== params) {
			return false;
		}
	} else {
		return false;
	}
};

/**
 * Gets Active Filters
 */
export const getActiveFilter = (taxonomies?: any) => {
	const object: any = {};

	// Get content From Filter.
	for (const key in getFilterObject()) {
		if (filterObject.hasOwnProperty(key)) {
			const element: any = filterObject[key];

			if (taxonomies) {
				taxonomies.forEach((taxonomy: any) => {
					if (filterObject[taxonomy]) {
						object[taxonomy] = filterObject[taxonomy];
					}
				});
			}
		}
	}
	return object;
};
