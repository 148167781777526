// tslint:disable:no-shadowed-variable

import { TaxonomyModel } from "@/data/taxonomy/taxonomy.model";
import { Taxonomy } from "@/data/taxonomy/taxonomy";

// State.
const state = {
	initialized: false,
	content: {}
};

const taxonomies = ["year"];

// Getters.
// You can think of them as computed properties for stores. Like computed properties, a getter's result is cached
// based on its dependencies, and will only re-evaluate when some of its dependencies have changed.
const getters = {
	getContent: (state: any): any => {
		return state.content;
		// if (taxonomies) {
		// 	if (Array.isArray(taxonomies)) {
		// 		for (const taxonomy of taxonomies) {
		// 			const selectedTaxonomies: any = {};
		// 			selectedTaxonomies[taxonomy] = state.content[taxonomy];
		// 			return selectedTaxonomies;
		// 		}
		// 	} else {
		// 		return state.content[taxonomies];
		// 	}
		// } else {
		// 	return state.content;
		// }
	}
};

// Mutations.
// The only way to actually change state in a Vuex store is by committing a mutation.
const mutations = {
	setContent: (state: any, taxonomies: any[]): void => {
		// Processes taxonomies to easier Assignment
		for (const taxonomy of taxonomies) {
			state.content[taxonomy.slug] = taxonomy.value.reduce((obj: any, item: any) => {
				obj[item.id] = item;
				return obj;
			}, {});
		}
	},
	finishedInitialization: (state: any): void => {
		state.initialized = true;
	}
};

// Actions are similar to mutations, the differences being that:
// · Instead of mutating the state, actions commit mutations.
// · Actions can contain arbitrary asynchronous operations.
const actions = {
	initModule: async ({ state, commit, dispatch }: any): Promise<void> => {
		console.log("Taxonomy :: initModule");
		// Get content
		await dispatch("getTaxonomies");

		// Finish
		commit("finishedInitialization");
		// console.log("category :: finishedInitialization")
	},

	getTaxonomies: async ({ state, commit, dispatch }: any): Promise<void> => {
		const content: any = [];
		// API Request of all Taxonomies
		for (const taxonomy of taxonomies) {
			const taxonomyData = await new TaxonomyModel(taxonomy).getList();
			content.push({ slug: taxonomy, value: taxonomyData });
		}

		commit("setContent", content);
		return Promise.resolve();
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
