import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import i18n from "@/i18n";

import { PageModel } from "@/data/page/page.model";

import { State, Getter, Action, Mutation } from "vuex-class";

@Component({
	name: "SamplePage"
})
export default class SamplePage extends Vue {
	@Getter("i18n/getCurrentLang") private currentLang!: string;
	@Getter("i18n/hasInitialized") private i18nHasInitialized!: boolean;

	@Mutation("state/setLoading") private setLoading!: any;

	private content: any = {};
	private contentIsReady: boolean = false;
	private url: string = window.location.href;

	private created() {
		this.getContent();
		if (this.$route.query.view){
			this.$router.push({ query: { view: undefined } });
		}
	}

	@Watch("currentLang")
	@Watch("i18nHasInitialized")
	private async getContent() {
		const slug = this.$route.params.slug;
		if (this.i18nHasInitialized) {
			this.setLoading(false);
			this.content = await new PageModel().getEntry({ related_recursive_calls: 1, slug });
			this.contentIsReady = true;
		}
	}
}
