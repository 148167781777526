import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import i18n from "@/i18n";

import { PersonModel } from "@/data/person/person.model";
import { DiscModel } from "@/data/disc/disc.model";
import { TaxonomyModel } from "@/data/taxonomy/taxonomy.model";

import { State, Getter, Action, Mutation } from "vuex-class";
import { addFilter, getFilterObject, getActiveFilter, evalFilter } from "@/data/route";

@Component({
	name: "PersonPage"
})
export default class PersonPage extends Vue {
	@Getter("i18n/getCurrentLang") private currentLang!: string;
	@Getter("i18n/hasInitialized") private i18nHasInitialized!: boolean;

	@Mutation("state/setLoading") private setLoading!: any;

	private content: any = {};
	private years: any = {};
	private contentIsReady: boolean = false;
	private url: string = window.location.href;

	private created() {
		this.getContent();
		if (this.$route.query.view === "list" || this.$route.query.view === undefined) {
			this.$router.push({ query: { view: "biography" } })
		}
	}

	@Watch("$route.query")
	@Watch("currentLang")
	@Watch("i18nHasInitialized")
	private async getContent() {
		const slug = this.$route.params.slug;
		if (this.i18nHasInitialized) {
			this.setLoading(false);
			this.content = await new PersonModel().getEntry({ related_recursive_calls: 2, per_page: "100", orderby: "title", order: "asc", slug });
			this.contentIsReady = true;
		}
	}

	private callEvalFilter(param: string, value: any) {
		if (this.$route.query && this.$route.query[param]) {
			return this.$route.query[param].includes(value);
		}
	}

	private callAddFilter(filter: any, value: any, checkbox?: boolean, extra?: any) {
		addFilter(filter, value, checkbox, extra);
	}
}
