import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import i18n from "@/i18n";

import { EntryModel } from "@/data/entry/entry.model";
import { TaxonomyModel } from "@/data/taxonomy/taxonomy.model";

import { State, Getter, Action, Mutation } from "vuex-class";
import { addFilter, getFilterObject, getActiveFilter, evalFilter } from "@/data/route";

@Component({
	name: "TimelinePage"
})
export default class TimelinePage extends Vue {
	@Getter("i18n/getCurrentLang") private currentLang!: string;
	@Getter("i18n/hasInitialized") private i18nHasInitialized!: boolean;

	@Mutation("state/setLoading") private setLoading!: any;

	private content: any = {};
	private contentIsReady: boolean = false;
	private years: any = {};
	private yearsIsReady: boolean = false;
	private entryTypes: any = {};
	private entryTypesIsReady: boolean = false;
	private url: string = window.location.href;
	
	private decades: any = [];

	private created() {
		this.getContent();
		this.getTaxonomies();
		if (this.$route.query.view) {
			this.$router.push({ query: { view: undefined } })
		}
	}

	private mounted() {
		window.addEventListener("scroll", this.sidebarEval);
	}

	@Watch("$route.query.entry_type")
	@Watch("currentLang")
	@Watch("i18nHasInitialized")
	private async getContent() {
		if (this.i18nHasInitialized) {
			this.contentIsReady = false;
			this.setLoading(false);
			const entryType = this.$route.query.entry_type;
			this.content = await new EntryModel().getList({ related_recursive_calls: 2, entry_type: entryType });
			this.contentIsReady = true;
			this.orderTimeLineYears();
			// Sort Timeline by Years
			this.content.sort((a: any, b: any) => {
				return a.years[0].name - b.years[0].name;
			});
		}
	}

	private async getTaxonomies() {
		this.years = await new TaxonomyModel("entry_year").getList();
		this.yearsIsReady = true;
		this.entryTypes = await new TaxonomyModel("entry_type").getList();
		this.entryTypesIsReady = true;
	}

	/*
		Function created to order years by decade and by ascending order.
	*/
	private orderTimeLineYears() {
		// create Decades array with decade and corresponding events
		for (const year of this.years) {
			const decade = Math.floor(parseInt(year.name, 10) / 10) * 10;

			if(!this.decades.some(d => d.decade === decade)) {
				this.decades.push({
					decade,
					events: this.getEventsFromDecade(decade)
				})
			}
		}
		
		const firstDecade = this.decades[0].decade
		const lastDecade = this.decades[this.decades.length - 1].decade
		
		const decadesNumber = []
		for (let d = firstDecade; d < lastDecade; d = d + 10) {
			decadesNumber.push(d)
		}

		let decadeFound = false

		for (const decadeNumber of decadesNumber) {
			for (const decade of this.decades) {
				if(decade.decade === decadeNumber) {
					decadeFound = true
				}
			}
			if (!decadeFound) {
				this.decades.push({
					decade: decadeNumber,
					events: []
				})
			}
			decadeFound = false
		}

		function compare(a, b) {
			if (a.decade < b.decade) {
				return -1;
			}
			if (a.decade > b.decade) {
				return 1;
			}
			return 0;
		}
		this.decades.sort(compare);
	}

	private getEventsFromDecade(decade) {
		const tempEvents = []

		for (const event of this.content) {
			if (event.years.length) {
				const year = Math.floor(parseInt(event.years[0].name, 10) / 10) * 10;
				if( year === decade) {
					tempEvents.push(event)
				}
			}
		}
		tempEvents.sort((a: any, b: any) => {
			return a.years[0].name - b.years[0].name;
		});

		return tempEvents;
	}

	private scrollTo(id: number) {
		const elTop = document.getElementById(id.toString()).offsetTop - 82;
		window.scrollTo({
			top: elTop,
			left: 0,
			behavior: "smooth"
		});
	}

	private sidebarEval() {
		const sidebar = document.querySelector(".timeline-page__body-years");
		const decades = document.querySelectorAll(".timeline-page__body-years button");
		const decadesContainers = document.querySelectorAll(".timeline-page__body-table > li");

		for (const container of decadesContainers) {
			if (container.getBoundingClientRect().top - 13 <= sidebar.getBoundingClientRect().top && container.getBoundingClientRect().bottom - 22 > sidebar.getBoundingClientRect().top){
				document.getElementById("button-" + container.id).classList.add("type--underline")
			} else {
				document.getElementById("button-" + container.id).classList.remove("type--underline")
			}
		}
	}
}
