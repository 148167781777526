import get from "lodash/get";

export class ImageAcf {
	private id?: string;
	private title?: string;
	private description?: string;
	private caption?: string;
	private alt?: string;
	private sizes: {
		original?: string | null;
		great?: string | null;
		huge?: string | null;
		large?: string | null;
		medium?: string | null;
		small?: string | null;
		thumbnail?: string | null;
	};

	constructor(object: any) {
		this.id = object.id;
		this.title = object.title;
		this.description = object.description;
		this.caption = object.caption;
		this.alt = object.alt;
		this.sizes = {
			original: get(object, "url"),
			thumbnail: get(object, "sizes.thumbnail"),
			small: get(object, "sizes.small"),
			medium: get(object, "sizes.medium"),
			large: get(object, "sizes.large"),
			great: get(object, "sizes.great"),
			huge: get(object, "sizes.huge")
		};
	}
}
