export class Query {
	public id?: number;
	public slug?: string;
	public author?: string;
	public dateGmt?: string;
	public modifiedGmt?: string;
	public status?: string;
	public title?: string;
	public template?: string;
	public lang?: string;
	// tslint:disable-next-line:variable-name
	public related_recursive_calls?: number;
	public order?: string;
	public orderby?: string;
	public filter?: string;
	public before?: Date | string;
	public after?: Date | string;
	public search?: string;
	public password?: string;
	public page?: string;
	// tslint:disable-next-line:variable-name
	public per_page?: string;
	// tslint:disable-next-line:variable-name
	public entry_type?: string;

	// Woocommerce
	public code?: string;
	public product?: any;
	public include?: any;

	constructor(object: null | string | number | Query) {
		this.related_recursive_calls = 1;
		if (object) {
			if (typeof object === "number") {
				this.id = object;
			} else if (typeof object === "string") {
				this.slug = object;
			} else if (typeof object === "object") {
				this.id = object.id;
				this.slug = object.slug;
				this.author = object.author;
				this.dateGmt = object.dateGmt;
				this.modifiedGmt = object.modifiedGmt;
				this.status = object.status;
				this.title = object.title;
				this.template = object.template;
				this.lang = object.lang;
				this.related_recursive_calls = object.related_recursive_calls;
				this.order = object.order;
				this.orderby = object.orderby;
				this.filter = object.filter;
				this.before = object.before;
				this.after = object.after;
				this.search = object.search;
				this.password = object.password;
				this.page = object.page;
				this.per_page = object.per_page;
				this.entry_type = object.entry_type;
				// Woocommerce
				this.code = object.code;
				this.product = object.product;
				this.include = object.include;
			}
		}
	}
}
