import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import i18n from "@/i18n";

import { DiscModel } from "@/data/disc/disc.model";
import { TaxonomyModel } from "@/data/taxonomy/taxonomy.model";

import { State, Getter, Action, Mutation } from "vuex-class";

@Component({
	name: "DiscPage"
})
export default class DiscPage extends Vue {
	@Getter("i18n/getCurrentLang") private currentLang!: string;
	@Getter("i18n/hasInitialized") private i18nHasInitialized!: boolean;

	@Mutation("state/setLoading") private setLoading!: any;

	private content: any = {};
	private years: any = {};
	private contentIsReady: boolean = false;
	private url: string = window.location.href;

	private image : number = 0;

	private created() {
		this.getContent();
		if (this.$route.query.view) {
			this.$router.push({ query: { view: undefined } })
		}
	}

	@Watch("currentLang")
	@Watch("i18nHasInitialized")
	private async getContent() {
		const slug = this.$route.params.slug;
		if (this.i18nHasInitialized) {
			this.setLoading(false);
			this.content = await new DiscModel().getEntry({ slug,  related_recursive_calls: 1 });
			this.contentIsReady = true;
		}
	}

	private async coverSlideshow(forward?: boolean) {
		if(forward) {
			this.image = this.image + 1
		}

		if (!forward) {
			this.image = this.image - 1
		}
	}
}
