import WPAPI, { WPRequest } from "wpapi";
import { Query } from "@/data/query";
import i18n from "@/i18n";

import store from "@/store";

export class WpApi {
	private connection: WPAPI | WPRequest;

	constructor() {
		this.connection = this.getConnectionConfig();
	}

	/**
	 * Returns a specific entry.
	 */
	public read(model: string, query?: Query) {
		let connection = this.connection.root(model);

		if (query) {
			connection = this.setParams(connection, query);
		}

		return connection.then((data: any) => {
			return data;
		});
	}

	/**
	 * Updates an entry by it's ID or slug.
	 */
	public update(model: string, query: Query, content: any) {
		let connection = this.connection.root(model);

		if (!query.id && !query.slug) {
			throw Error(`WpApi :: Can only update an '${model}' entry by ID or slug. Aborting.`);
		}

		connection = this.setParams(this.connection, query);

		return connection.update(content).then((data: any) => {
			return data;
		});
	}

	/**
	 * Creates an entry.
	 */
	public create(model: string, content: any) {
		const connection = this.connection.root(model);

		return connection.create(content).then((data: any) => {
			return data;
		});
	}

	/**
	 * Deletes an entry by
	 */
	public delete(model: string, query: Query) {
		let connection = this.connection.root(model);

		if (!query.id && !query.slug) {
			throw Error(`WpApi:: Can only delete an '${model}' entry by ID or slug. Aborting.`);
		}

		connection = this.setParams(this.connection, query);

		return connection.delete().then((data: any) => {
			return data;
		});
	}

	/**
	 * Add queries to request by adding multiple .param()'s.
	 */
	private setParams(connection: any, queries: Query) {
		Object.keys(queries).forEach(query => {
			const value = queries[query as keyof Query];
			connection = connection.param(query, value);
		});

		// Set language

		const lang = store.getters["i18n/getCurrentWpmlLang"];
		connection = connection.param("lang", lang);

		return connection;
	}

	/**
	 * Returns WPAPI connection configuration based on environment variables.
	 */
	private getConnectionConfig(): WPAPI {
		if (process.env.API_ENDPOINT) {
			if (process.env.API_JWT_TOKEN) {
				// JWT Token Auth
				return new WPAPI({
					endpoint: process.env.API_ENDPOINT
				}).setHeaders({
					Authorization: `Bearer ${process.env.JWT_TOKEN}`
				});
			} else if (process.env.API_AUTH_USER && process.env.API_AUTH_PASS) {
				// Basic Auth
				return new WPAPI({
					endpoint: process.env.API_ENDPOINT,
					username: process.env.API_AUTH_USER,
					password: process.env.API_AUTH_PASS
				});
			} else {
				return new WPAPI({ endpoint: process.env.API_ENDPOINT });
			}
		} else {
			throw Error("WpApi :: API endpoint not defined. Aborting");
		}
	}
}
