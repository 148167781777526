import { WpApi } from "@/data/wp-api";
import { Query } from "@/data/query";
import { Document } from "@/data/document/document";

import { ImageAcf } from "@/data/image_acf";

import { Image } from "@/data/image";

import get from "lodash/get";

import store from "@/store";

export class DocumentModel {
	private namespace: string;
	private modelName: string;
	private api: WpApi;

	constructor() {
		this.namespace = "wp/v2";
		this.modelName = "documents";
		this.api = new WpApi();
	}

	/**
	 * Returns a single page.
	 */
	public async getEntry(queries?: Query): Promise<Document> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		
		try {
			const data = await this.api.read(
				`${this.namespace}/${this.modelName}`,
				new Query({ ...queries, lang })
			);

			return this.processEntry(data[0]);
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Returns a list of pages.
	 */
	public async getList(queries?: Query): Promise<any> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = await this.api.read(`${this.namespace}/${this.modelName}`, new Query({ ...queries, lang }));
			
			const processedData = data.map((entry: any) => {
				return this.processEntry(entry);
			});

			if (!data._paging) {
				return data;
			}

			return {
				content: processedData,
				total: Number(data._paging.total),
				totalPages: Number(data._paging.totalPages),
			}
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Processes a Entry.
	 */
	public processEntry(data: any): Document {
		// console.log("DiscModel :: processEntry :: data -> ", data);

		// Process Cover
		data.acf.picture ? (data.acf.picture = new ImageAcf(data.acf.picture)) : (data.acf.picture = null);

		return {
			id: data.id,
			title: data.title.rendered,
			slug: data.slug,
			type: data.type,
			
			people: data.acf.people,
			date: data.acf.date,
			description: data.acf.description,
			picture: data.acf.picture,
			file: data.acf.file
		};
	}
}
