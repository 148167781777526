import { WpApi } from "@/data/wp-api";
import { Query } from "@/data/query";
import { Person } from "@/data/person/person";

import { ImageAcf } from "@/data/image_acf";

import get from "lodash/get";

import store from "@/store";
import { TaxonomyModel } from "@/data/taxonomy/taxonomy.model";
import { DiscModel } from "@/data/disc/disc.model";
import { DocumentModel } from "@/data/document/document.model";
import { EssayModel } from "@/data/essay/essay.model";
import { EntryModel } from "@/data/entry/entry.model";


export class PersonModel {
	private namespace: string;
	private modelName: string;
	private api: WpApi;

	constructor() {
		this.namespace = "wp/v2";
		this.modelName = "persons";
		this.api = new WpApi();
	}

	/**
	 * Returns a single page.
	 */
	public async getEntry(queries?: Query): Promise<Person> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = await this.api.read(
				`${this.namespace}/${this.modelName}`,
				new Query({ ...queries, lang })
			);

			return this.processEntry(data[0]);
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Returns a list of pages.
	 */
	public async getList(queries?: Query): Promise<any> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = await this.api.read(`${this.namespace}/${this.modelName}`, new Query({ ...queries, lang }));

			const processedData = data.map((entry: any) => {
				return this.processEntry(entry);
			});

			if (!data._paging) {
				return data;
			}

			return {
				content: processedData,
				total: Number(data._paging.total),
				totalPages: Number(data._paging.totalPages),
			}
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Processes a Entry.
	 */
	public processEntry(data: any): Person {
		// console.log("person.model : processEntry : data ->", data )
		let processedDiscs = data.acf.discs;
		let processedDocuments = data.acf.related_documents;
		let processedEssays = data.acf.related_essays;
		let processedEvents = data.acf.related_entries;
		if (data.related_ids) {
			if (data.acf.discs && data.acf.discs.length) {
				processedDiscs = data.acf.discs.map((id: any) => {
					if (data.related_ids[id]) {
						return new DiscModel().processEntry(data.related_ids[id]);
					}
				});
			}
			if (data.acf.related_documents && data.acf.related_documents.length) {
				processedDocuments = data.acf.related_documents.map((id: any) => {
					if (data.related_ids[id]) {
						return new DocumentModel().processEntry(data.related_ids[id]);
					}
				});
			}
			if (data.acf.related_essays && data.acf.related_essays.length) {
				processedEssays = data.acf.related_essays.map((id: any) => {
					if (data.related_ids[id]) {
						return new EssayModel().processEntry(data.related_ids[id]);
					}
				});
			}
			if (data.acf.related_entries && data.acf.related_entries.length) {
				processedEvents = data.acf.related_entries.map((id: any) => {
					if (data.related_ids[id]) {
						return new EntryModel().processEntry(data.related_ids[id]);
					}
				});
			}
		}



		// Process Portrait
		data.acf.portrait ? (data.acf.portrait = new ImageAcf(data.acf.portrait)) : (data.acf.portrait = null);

		return {
			id: data.id,
			title: data.title.rendered,
			slug: data.slug,
			type: data.type,
			role: data.person_type,
			
			portrait: data.acf.portrait,
			birth: data.acf.birth_date,
			death: data.acf.death_date,
			location: data.acf.location,
			biography: data.acf.biography,

			discs: processedDiscs,
			documents: processedDocuments,
			essays: processedEssays,
			events: processedEvents
		};
	}
}
