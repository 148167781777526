import { render, staticRenderFns } from "./ani-carroussel.block.pug?vue&type=template&id=4f4f7d4c&scoped=true&lang=pug&"
import script from "./ani-carroussel.block.ts?vue&type=script&lang=ts&"
export * from "./ani-carroussel.block.ts?vue&type=script&lang=ts&"
import style0 from "./ani-carroussel.block.sass?vue&type=style&index=0&id=4f4f7d4c&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f4f7d4c",
  null
  
)

export default component.exports