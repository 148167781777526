import { render, staticRenderFns } from "./documents.page.pug?vue&type=template&id=6286d12c&scoped=true&lang=pug&"
import script from "./documents.page.ts?vue&type=script&lang=js&"
export * from "./documents.page.ts?vue&type=script&lang=js&"
import style0 from "./documents.page.sass?vue&type=style&index=0&id=6286d12c&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6286d12c",
  null
  
)

export default component.exports