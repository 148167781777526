import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import i18n from "@/i18n";

import router from "@/router";

import instance from "@/main";
import { PageModel } from "@/data/page/page.model";
import { DocumentModel } from "@/data/document/document.model";

import { State, Getter, Action, Mutation } from "vuex-class";
import { addFilter, getFilterObject, getActiveFilter, evalFilter } from "@/data/route";
import { discover } from "wpapi";
import { Query } from "@/data/query";

@Component({
	name: "DocumentsPage"
})
export default class DocumentsPage extends Vue {
	@Getter("i18n/getCurrentLang") private currentLang!: string;
	@Getter("i18n/hasInitialized") private i18nHasInitialized!: boolean;

	@Getter("taxonomy/getContent") private taxonomies!: any[];

	@Mutation("state/setLoading") private setLoading!: any;

	private content: any = {};
	private currentPage: number = 1;
	private contentIsReady: boolean = false;
	private url: string = window.location.href;
	private activeDisc : number = 0;

	private created() {
		if (this.$route.query.view !== "grid" && this.$route.query.view !== "list" ) {
			if (window.innerWidth < 992){
				addFilter("view", "grid");
			} else {
				addFilter("view", "list");
			}
		}
		if (this.$route.query.page) {
			this.currentPage = Number(this.$route.query.page);
		} else {
			addFilter("page", "1");
		}
		this.getContent();
	}

	@Watch("$route.query.page")
	@Watch("currentLang")
	@Watch("i18nHasInitialized")
	private async getContent() {
		const query = this.$route.query;
		if (this.i18nHasInitialized) {
			this.setLoading(false);
			this.contentIsReady = false;
			this.content = await new DocumentModel().getList({ related_recursive_calls: 1, ...query });
			this.contentIsReady = true;
			this.currentPage = Number(this.$route.query.page);
		}
	}

	private callEvalFilter(param: string, value: any) {
		if(this.$route.query && this.$route.query[param]) { 
			return this.$route.query[param].includes(value);
		}
	}

	private callAddFilter(filter: any, value: any, checkbox?: boolean, extra?: any) {
		addFilter(filter, value, checkbox, extra);
	}
}
