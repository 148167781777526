import { WpApi } from "@/data/wp-api";
import { Query } from "@/data/query";
import { Entry } from "@/data/entry/entry";

import { DiscModel } from "@/data/disc/disc.model";
import { PersonModel } from "@/data/person/person.model";
import { MusicModel } from "@/data/music/music.model";

import { ImageAcf } from "@/data/image_acf";

import get from "lodash/get";

import store from "@/store";
import { TaxonomyModel } from "../taxonomy/taxonomy.model";

export class EntryModel {
	private namespace: string;
	private modelName: string;
	private api: WpApi;

	constructor() {
		this.namespace = "wp/v2";
		this.modelName = "entries";
		this.api = new WpApi();
	}

	/**
	 * Returns a single page.
	 */
	public async getEntry(id: number, queries?: Query): Promise<Entry> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = await this.api.read(
				`${this.namespace}/${this.modelName}/${id}`,
				new Query({ ...queries, lang })
			);

			return this.processEntry(data);
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Returns a list of pages.
	 */
	public async getList(queries?: Query): Promise<Entry[]> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = await this.api.read(`${this.namespace}/${this.modelName}`, new Query({ ...queries, lang }));

			return data.map((entry: any) => {
				return this.processEntry(entry);
			});
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Processes a Entry.
	 */
	public processEntry(data: any): Entry {
		// console.log(" EntryModel :: Data : ", data);

		// Process Posts
		let processedPersons = null;
		let processedDiscs = null;
		if (data.related_ids) {
			if (data.acf.persons) {
				processedPersons = data.acf.persons.map((id: any) => {
					if (data.related_ids[id]) {
						return new PersonModel().processEntry(data.related_ids[id]);
					}
				});
			}
			if (data.acf.discs) {
				processedDiscs = data.acf.discs.map((id: number) => {
					if (data.related_ids[id]) {
						return new DiscModel().processEntry(data.related_ids[id]);
					}
				});
			}
		}
		// Process Taxonomies	
		let processedYears = null;
		let processedEntryTypes = null;
		if (data.related_taxonomies) {
			if (data.entry_year) {
				processedYears = data.entry_year.map((id: number) => {
					if (data.related_taxonomies[id]) {
						return new TaxonomyModel("entry_year").processEntry(data.related_taxonomies[id])
					}
				});
			}
			if (data.entry_type) {
				processedEntryTypes = data.entry_type.map((id: number) => {
					if (data.related_taxonomies[id]) {
						return new TaxonomyModel("entry_type").processEntry(data.related_taxonomies[id])
					}
				});
			}
		}


		return {
			id: data.id,
			title: data.title.rendered,
			slug: data.slug,
			type: data.type,

			date: data.acf.date,
			tag: data.acf.tag, 
			description: data.acf.description,

			audioTitle: data.acf.audio_title,
			
			entryTypes: processedEntryTypes,
			image: data.acf.image || null,
			years: processedYears,
			headline: data.acf.headline,
			discs: processedDiscs,
			persons: processedPersons,
		};
	}
}
