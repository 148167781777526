import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({
	name: "LoadingBlock"
})
export default class LoadingScreenComponent extends Vue {
	@Getter("state/getLoading") private isLoading!: boolean;

	private anim: any = null;
	private animationSpeed: number = 1;

	private data: any = {
		v: "5.5.10",
		fr: 30,
		ip: 0,
		op: 120,
		w: 1080,
		h: 1080,
		nm: "Comp 1",
		ddd: 0,
		assets: [],
		layers: [
			{
				ddd: 0,
				ind: 1,
				ty: 4,
				nm: "small",
				sr: 1,
				ks: {
					o: { a: 0, k: 100, ix: 11 },
					r: {
						a: 1,
						k: [
							{ i: { x: [0.4], y: [1] }, o: { x: [0.6], y: [0] }, t: 10, s: [0] },
							{ t: 90, s: [720] }
						],
						ix: 10
					},
					p: { a: 0, k: [540, 540, 0], ix: 2 },
					a: {
						a: 1,
						k: [
							{
								i: { x: 0, y: 1 },
								o: { x: 0.315, y: 0 },
								t: 4,
								s: [0, 0, 0],
								to: [-66.667, 0, 0],
								ti: [66.667, 0, 0]
							},
							{
								i: { x: 0, y: 0 },
								o: { x: 0.167, y: 0.167 },
								t: 20,
								s: [-400, 0, 0],
								to: [0, 0, 0],
								ti: [0, 0, 0]
							},
							{
								i: { x: 0.667, y: 1 },
								o: { x: 0.167, y: 0 },
								t: 81,
								s: [-400, 0, 0],
								to: [66.667, 0, 0],
								ti: [-66.667, 0, 0]
							},
							{ t: 97, s: [0, 0, 0] }
						],
						ix: 1
					},
					s: { a: 0, k: [100, 100, 100], ix: 6 }
				},
				ao: 0,
				shapes: [
					{
						ty: "gr",
						it: [
							{
								d: 1,
								ty: "el",
								s: { a: 0, k: [150, 150], ix: 2 },
								p: { a: 0, k: [0, 0], ix: 3 },
								nm: "Ellipse Path 1",
								mn: "ADBE Vector Shape - Ellipse",
								hd: false
							},
							{
								ty: "fl",
								c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
								o: { a: 0, k: 100, ix: 5 },
								r: 1,
								bm: 0,
								nm: "Fill 1",
								mn: "ADBE Vector Graphic - Fill",
								hd: false
							},
							{
								ty: "tr",
								p: { a: 0, k: [0, 0], ix: 2 },
								a: { a: 0, k: [0, 0], ix: 1 },
								s: { a: 0, k: [100, 100], ix: 3 },
								r: { a: 0, k: 0, ix: 6 },
								o: { a: 0, k: 100, ix: 7 },
								sk: { a: 0, k: 0, ix: 4 },
								sa: { a: 0, k: 0, ix: 5 },
								nm: "Transform"
							}
						],
						nm: "Ellipse 1",
						np: 2,
						cix: 2,
						bm: 0,
						ix: 1,
						mn: "ADBE Vector Group",
						hd: false
					}
				],
				ip: 0,
				op: 150,
				st: 0,
				bm: 0
			},
			{
				ddd: 0,
				ind: 2,
				ty: 4,
				nm: "large",
				sr: 1,
				ks: {
					o: { a: 0, k: 100, ix: 11 },
					r: { a: 0, k: 0, ix: 10 },
					p: { a: 0, k: [504.18, 524.18, 0], ix: 2 },
					a: { a: 0, k: [-35.82, -15.82, 0], ix: 1 },
					s: { a: 0, k: [100, 100, 100], ix: 6 }
				},
				ao: 0,
				shapes: [
					{
						ty: "gr",
						it: [
							{
								d: 1,
								ty: "el",
								s: { a: 0, k: [1080, 1080], ix: 2 },
								p: { a: 0, k: [0, 0], ix: 3 },
								nm: "Ellipse Path 1",
								mn: "ADBE Vector Shape - Ellipse",
								hd: false
							},
							{
								ty: "fl",
								c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
								o: { a: 0, k: 100, ix: 5 },
								r: 1,
								bm: 0,
								nm: "Fill 1",
								mn: "ADBE Vector Graphic - Fill",
								hd: false
							},
							{
								ty: "tr",
								p: { a: 0, k: [0, 0], ix: 2 },
								a: { a: 0, k: [0, 0], ix: 1 },
								s: { a: 0, k: [100, 100], ix: 3 },
								r: { a: 0, k: 0, ix: 6 },
								o: { a: 0, k: 100, ix: 7 },
								sk: { a: 0, k: 0, ix: 4 },
								sa: { a: 0, k: 0, ix: 5 },
								nm: "Transform"
							}
						],
						nm: "Ellipse 1",
						np: 2,
						cix: 2,
						bm: 0,
						ix: 1,
						mn: "ADBE Vector Group",
						hd: false
					}
				],
				ip: 0,
				op: 150,
				st: 0,
				bm: 0
			}
		],
		markers: []
	};

	private animation: any = {
		animationData: this.data
	};

	private handleAnimation(anim: any) {
		this.anim = anim;
	}

	private stop() {
		this.anim.stop();
	}

	private play() {
		this.anim.play();
	}

	private pause() {
		this.anim.pause();
	}

	private onSpeedChange() {
		this.anim.setSpeed(this.animationSpeed);
	}
}
