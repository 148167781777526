import Vue from "vue";

import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import store from "./store";
import "./registerServiceWorker";

// Library
// import * as VeeValidate from "vee-validate";
// const vueMoment = require("vue-moment");
import moment from "moment";
// tslint:disable-next-line: no-var-requires
const Lottie = require("vue-lottie");
// tslint:disable-next-line: no-var-requires
import vueHeadful from "vue-headful";
// tslint:disable-next-line: no-var-requires
const VueSanitize = require("vue-sanitize");

// Blocks
import Vector from "@/components/blocks/vector/vector.vue";
import ImageBlock from "@/components/blocks/image/image.block.vue";
import HeaderBlock from "@/components/blocks/header-block/header.block.vue";
import FooterBlock from "@/components/blocks/footer-block/footer.block.vue";
import LoadingScreen from "@/components/blocks/loading-screen/loading.screen.vue";
import LoadingBlock from "@/components/blocks/loading-block/loading.block.vue";
import SlideshowBlock from "@/components/blocks/slideshow-block/slideshow.block.vue";

// Specific
import EntryBlock from "@/components/blocks/entry-block/entry.block.vue";
import PersonBlock from "@/components/blocks/person-block/person.block.vue";
import DiscBlock from "@/components/blocks/disc-block/disc.block.vue";
import ArticleBlock from "@/components/blocks/article-block/article.block.vue";
import FlexibleContentBlock from "@/components/blocks/flexible-content-block/flexible-content.block.vue";
import AniCarrousselBlock from "@/components/blocks/ani-carroussel-block/ani-carroussel.block.vue";

Vue.config.productionTip = false;

// Blocks.
Vue.component("vector", Vector);
Vue.component("image-block", ImageBlock);
Vue.component("header-block", HeaderBlock);
Vue.component("footer-block", FooterBlock);
Vue.component("loading-screen", LoadingScreen);
Vue.component("loading-block", LoadingBlock);
Vue.component("slideshow-block", SlideshowBlock)

// Specific
Vue.component("entry-block", EntryBlock);
Vue.component("person-block", PersonBlock);
Vue.component("disc-block", DiscBlock);
Vue.component("article-block", ArticleBlock);
Vue.component("flexible-content-block", FlexibleContentBlock);
Vue.component("ani-carroussel-block", AniCarrousselBlock);

// Library
Vue.component("vue-headful", vueHeadful);
Vue.component("lottie", Lottie);
// tslint:disable-next-line: no-var-requires
// Vue.use(require("vue-moment"), { moment: Moment });
// Vue.use(require('vue-moment'));
Vue.prototype.moment = moment
// tslint:disable-next-line
require('moment/locale/pt')

Vue.use(VueSanitize, {
	allowedTags: [
		"h1",
		"h2",
		"h3",
		"h4",
		"h5",
		"h6",
		"b",
		"i",
		"em",
		"strong",
		"a",
		"p",
		"ul",
		"ol",
		"li",
		"blockquote",
		"pre",
		"code",
		"ins",
		"img"
	],
	allowedAttributes: {
		"*": ["style"],
		a: ["href"],
		insc: ["datetime"],
		del: ["datetime"],
		img: ["src"]
	},
	allowedStyles: {
		"*": { "text-align": [/^left$/, /^right$/, /^center$/] }
	}
});

// The Vue instance.
export default new Vue({
	router,
	i18n,
	store,
	render: h => h(App)
}).$mount("#app");

// Initialization of stores.
store.dispatch("i18n/initModule");
store.dispatch("taxonomy/initModule");

declare module "vue/types/vue" {
	interface Vue {
		$moment: any;
		moment: any;
	}
}
