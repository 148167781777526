import Vue from "vue";
import Router from "vue-router";

import HomePage from "@/components/pages/home/home.page.vue";
import TimelinePage from "@/components/pages/timeline/timeline.page.vue";
import DiscsPage from "@/components/pages/discs/discs.page.vue";
import PersonsPage from "@/components/pages/persons/persons.page.vue";
import DocumentsPage from "@/components/pages/documents/documents.page.vue";
import DiscPage from "@/components/pages/disc/disc.page.vue";
import PersonPage from "@/components/pages/person/person.page.vue";
import ArticlePage from "@/components/pages/article/article.page.vue";
import NewsPage from "@/components/pages/news/news.page.vue";
import EssaysPage from "@/components/pages/essays/essays.page.vue";
import SamplePage from "@/components/pages/sample/sample.page.vue";
import SearchPage from "@/components/pages/search/search.page.vue";

Vue.use(Router);

const router = new Router({
	mode: "history",
	routes: [
		{
			path: "/",
			redirect: "/pt"
		},
		{
			path: "/:lang",
			component: HomePage,
			name: "HomePage"
		},
		{
			path: "/:lang/timeline",
			component: TimelinePage,
			name: "TimelinePage"
		},
		{
			path: "/:lang/discs",
			component: DiscsPage,
			name: "DiscsPage"
		},
		{
			path: "/:lang/discs/:slug",
			component: DiscPage,
			name: "DiscPage"
		},
		{
			path: "/:lang/persons",
			component: PersonsPage,
			name: "PersonsPage"
		},
		{
			path: "/:lang/documents",
			component: DocumentsPage,
			name: "DocumentsPage"
		},
		{
			path: "/:lang/persons/:slug",
			component: PersonPage,
			name: "PersonPage"
		},
		{
			path: "/:lang/news",
			component: NewsPage,
			name: "NewsPage"
		},
		{
			path: "/:lang/essays",
			component: EssaysPage,
			name: "EssaysPage"
		},
		{
			path: "/:lang/news/:slug",
			component: ArticlePage,
			name: "ArticlePage"
		},
		{
			path: "/:lang/pages/:slug",
			component: SamplePage,
			name: "SamplePage"
		},
		{
			path: "/:lang/search",
			component: SearchPage,
			name: "SearchPage"
		},
		{
			path: "**",
			redirect: "/"
		}
	]
});

export default router;
