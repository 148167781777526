import get from "lodash/get";

import store from "@/store";

import { WpApi } from "@/data/wp-api";
import { Query } from "@/data/query";
import { Taxonomy } from "@/data/taxonomy/taxonomy";
import { Image } from "@/data/image";

export class TaxonomyModel {
	private namespace: string;
	private modelName: string;
	private api: WpApi;

	constructor(taxonomy: string) {
		this.namespace = "wp/v2";
		this.modelName = taxonomy;
		this.api = new WpApi();
	}

	/**
	 * Returns a single Taxonomy.
	 */
	public async getEntry(id: number): Promise<Taxonomy> {
		const lang = store.getters["i18n/getCurrentLang"];

		// console.log("ID : ", id);

		try {
			const data = await this.api.read(`${this.namespace}/${this.modelName}`, new Query({ id }));

			return this.processEntry(data[0]);
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Returns a list of Taxnomies.
	 */
	public async getList(queries?: Query): Promise<Taxonomy[]> {
		const lang = store.getters["i18n/getCurrentLang"];

		try {
			const data = await this.api.read(
				`${this.namespace}/${this.modelName}`,
				new Query({ ...queries, lang })
			);

			return data.map((entry: any) => {
				return this.processEntry(entry);
			});
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Processes a page entry.
	 */
	public processEntry(data: any, processRelations: boolean = true): Taxonomy {
		// console.log("TaxonomyModel :: processEntry : data", data);
		const processedChildren : any = [];
		if (data.related_taxonomies) {
			if (data.children_taxonomies) {
				for (const id in data.children_taxonomies) {
					if(data.children_taxonomies[id]) {
						processedChildren.push(new TaxonomyModel("children_taxonomies").processEntry(data.children_taxonomies[id]))
					}
				}
			}
		}
		const content: Taxonomy = {
			id: data.id,
			slug: data.slug,
			name: data.name,
			count: data.count,
			parent: data.parent,
			children: processedChildren
		};

		// console.log("ArticleModel :: processEntry : content", content);

		return content;
	}
}
