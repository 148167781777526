import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import i18n from "@/i18n";

import { OptionModel } from "@/data/option/option.model";

import { State, Getter, Action, Mutation } from "vuex-class";

@Component({
	name: "AniCarrousselBlock"
})
export default class AniCarrousseBlock extends Vue {
	@Action("i18n/changeLanguage") private changeLanguage!: any;
	@Getter("i18n/getAvailableLangs") private availableLangs!: string[];

	@Getter("taxonomy/getContent") private taxonomies!: any[];

	@Prop() private entries : any[];
	@Prop() private cameraSpeed! : number;

	private content: any = null;

	private scenePerspective: number = 20;
	private scenePerspectiveOriginX: number = 50;
	private scenePerspectiveOriginY: number = -5;
	private itemZ: number = 10;
	private cameraZ: number = 0;
	private cameraIncrement: number = 10;
	private viewportHeight: number = 0;
	private cameraInterval: any = null;

	private mounted() {
		this.setSceneHeight();
		this.cameraInterval = setInterval(() => this.moveCamera(), 3000);
	}

	private moveCamera() {
		if (this.$route.name !== "HomePage") {
			clearInterval(this.cameraInterval)
		}
		const scene3d = document.getElementById("scene3D");
		if (scene3d && this.cameraZ >= (this.entries.length - 1) * this.cameraIncrement) {
			scene3d.style.transition = "600ms ease";
			this.cameraZ = 0;
		} else {
			scene3d.style.transition = "600ms ease";
			this.cameraZ = this.cameraZ + this.cameraIncrement;
		}
		this.getOpacityValue();
	}

	@Watch("$route.params")
	private resetInterval() {
		clearInterval(this.cameraInterval)
		this.cameraInterval = setInterval(() => this.moveCamera(), 3000);
	}

	private setSceneHeight() {
		const numberOfItems = this.entries.length; // Or number of items you have in `.scene3D`

		const height =
			window.innerHeight +
			this.scenePerspective * this.cameraSpeed +
			this.itemZ * this.cameraSpeed * numberOfItems;
		
		// Update --viewportHeight value
		this.viewportHeight = height;
	}

	private getOpacityValue() {
		const elements = document.getElementsByClassName("ani-carroussel_scene3D--card") as HTMLCollection;

		for (let i = 0; i < elements.length; i++) {
			const element = elements[i] as HTMLElement;

			const style = window.getComputedStyle(element);
			const index = Number(style.getPropertyValue("--index"));

			if (index * this.cameraIncrement === this.cameraZ) {
				element.style.opacity = "1";
				element.style.display = "block";
				console.log("Element ->", i)
			} else if (index * this.cameraIncrement > this.cameraZ) {
				element.style.opacity = "0.5";
				element.style.display = "block";
			} else if (index * this.cameraIncrement < this.cameraZ) {
				element.style.opacity = "0";
				element.style.display = "none";
			}
		}
	}
}
