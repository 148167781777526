import { WpApi } from "@/data/wp-api";
import { Query } from "@/data/query";
import { Article } from "@/data/article/article";

import { ImageAcf } from "@/data/image_acf";

import get from "lodash/get";

import store from "@/store";
import { TaxonomyModel } from "@/data/taxonomy/taxonomy.model";
import { DiscModel } from "@/data/disc/disc.model";
import { PersonModel } from "@/data/person/person.model";
import { EntryModel } from "@/data/entry/entry.model";

export class ArticleModel {
	private namespace: string;
	private modelName: string;
	private api: WpApi;

	constructor() {
		this.namespace = "wp/v2";
		this.modelName = "posts";
		this.api = new WpApi();
	}

	/**
	 * Returns a single page.
	 */
	public async getEntry(queries?: Query): Promise<Article> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = await this.api.read(
				`${this.namespace}/${this.modelName}`,
				new Query({ ...queries, lang })
			);

			return this.processEntry(data[0]);
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Returns a list of pages.
	 */
	public async getList(queries?: Query): Promise<any> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = await this.api.read(`${this.namespace}/${this.modelName}`, new Query({ ...queries, lang }));

			const processedData = data.map((entry: any) => {
				return this.processEntry(entry);
			});

			if (!data._paging) {
				return data;
			}
			
			return {
				content: processedData,
				total: Number(data._paging.total),
				totalPages: Number(data._paging.totalPages),
			}
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Processes a Entry.
	 */
	public processEntry(data: any): Article {
		// console.log("article.model : processEntry : data ->", data )
		
		let processedDiscs = data.acf.related_discs;
		let processedPersons = data.acf.related_persons;
		let processedEntries = data.acf.related_entries;
		let processedNews = data.acf.related_news;
		if (data.related_ids) {
			if (data.acf.related_discs && data.acf.related_discs.length) {
				processedDiscs = data.acf.related_discs.map((id: any) => {
					if (data.related_ids[id]) {
						return new DiscModel().processEntry(data.related_ids[id]);
					}
				});
			}
			if (data.acf.related_persons && data.acf.related_persons.length) {
				processedPersons = data.acf.related_persons.map((id: any) => {
					if (data.related_ids[id]) {
						return new PersonModel().processEntry(data.related_ids[id]);
					}
				});
			}
			if (data.acf.related_entries && data.acf.related_entries.length) {
				processedEntries = data.acf.related_entries.map((id: any) => {
					if (data.related_ids[id]) {
						return new EntryModel().processEntry(data.related_ids[id]);
					}
				});
			}
			if (data.acf.related_news && data.acf.related_news.length) {
				processedNews = data.acf.related_news.map((id: any) => {
					if (data.related_ids[id]) {
						return new ArticleModel().processEntry(data.related_ids[id]);
					}
				});
			}
		}

		// Process Portrait
		data.acf.image ? (data.acf.image = new ImageAcf(data.acf.image)) : (data.acf.image = null);

		// Process Flexible Content
		let processedFlexibleContent = data.acf.flexible_content;
		if (data.acf.flexible_content && data.acf.flexible_content.length) {
			processedFlexibleContent = data.acf.flexible_content.map((line:any) => {
				if (line.acf_fc_layout === "image" && line.images.length) {
					line.images = line.images.map((image:any) => {
						return new ImageAcf(image);
					})
				}
				if (data.related_ids && line.acf_fc_layout === "relation" && line.relations.length) {
					line.relations = line.relations.map((id:any) => {
						if (data.related_ids && data.related_ids[id].type === "person") {
							return new PersonModel().processEntry(data.related_ids[id]);
						} else if (data.related_ids && data.related_ids[id].type === "disc") {
							return new DiscModel().processEntry(data.related_ids[id]);
						} else if (data.related_ids && data.related_ids[id].type === "entry") {
							return new EntryModel().processEntry(data.related_ids[id]);
						}
					})
				}
				if (data.related_ids && line.acf_fc_layout === "document" && line.file) {
					console.log("Process Document !! ")
					line.file = data.related_ids[line.file];
				}
				return line;
			})
		}
		
		// Process Categories 
		let processedTags = data.tags;
		if (data.related_taxonomies) {
			if (data.tags.length) {
				processedTags = data.tags.map((id:number) => {
					return new TaxonomyModel("category").processEntry(data.related_taxonomies[id])
				})
			}
		}


		return {
			id: data.id,
			title: data.title.rendered,
			slug: data.slug,
			tags: processedTags,
			
			image: data.acf.image,
			date: data.acf.date,
			headline: data.acf.headline,

			relatedDiscs: processedDiscs,
			relatedPersons: processedPersons,
			relatedEntries: processedEntries,
			relatedNews: processedNews,

			flexibleContent: data.acf.flexible_content
		};
	}
}
